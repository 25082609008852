import { AccessRules } from './access-rules';

export enum ControlTypeEnum {
  IC = 'ic',
  PIK_COMFORT = 'comfort',
  KM = 'km',
  SG = 'sg',
  PP = 'pp',
  GEO = 'geo',
}

export enum FlowTypeEnum {
  IC = 0,
  PIK_COMFORT = 1,
  KM = 2,
  SG = 3,
  PP = 4,
  GEO = 5,
}

export const CONTROL_TYPE_IDS = {
  [ControlTypeEnum.IC]: [1, 2, 4, 21],
  [ControlTypeEnum.PIK_COMFORT]: [3],
  [ControlTypeEnum.KM]: [5],
};

export const CONTROL_TYPE_FLOW_IDS = {
  [ControlTypeEnum.IC]: 0,
  [ControlTypeEnum.PIK_COMFORT]: 1,
  [ControlTypeEnum.KM]: 2,
};

export const CONTROL_TYPE_ROLE_IDS = {
  [ControlTypeEnum.PIK_COMFORT]: 11,
  [ControlTypeEnum.KM]: 15,
};

export const CADASTRAL_ADMIN_ROLE_ID = 14;
export const PIK_COMFORT_ADMIN_ROLE_ID = 10;
export const SUPER_ADMIN_ROLE_ID = 1;

export const CONTROL_TYPE_OPTIONS = [
  {
    id: ControlTypeEnum.IC,
    icon: '#helmet',
    rule: AccessRules.IC,
    title: 'Сдача работ на строительной площадке',
    flowType: FlowTypeEnum.IC,
  },
  {
    id: ControlTypeEnum.PIK_COMFORT,
    icon: '#home',
    rule: AccessRules.PIK_COMFORT,
    title: 'Сдача систем ПИК Комфорту',
    flowType: FlowTypeEnum.PIK_COMFORT,
  },
  {
    id: ControlTypeEnum.KM,
    icon: '#house',
    rule: AccessRules.KM,
    title: 'Управление земельных отношений, кадастрового учета и технической инвентаризации',
    flowType: FlowTypeEnum.KM,
  },
  {
    id: ControlTypeEnum.SG,
    icon: '#house',
    rule: AccessRules.SG,
    title: 'Сдача работ СпецГраду',
    flowType: FlowTypeEnum.SG,
  },
  {
    id: ControlTypeEnum.PP,
    icon: '#preliminary-acceptance',
    rule: AccessRules.PP,
    title: 'Сдача работ по предварительной приемке',
    flowType: FlowTypeEnum.PP,
  },
  {
    id: ControlTypeEnum.GEO,
    icon: '#surveyor',
    rule: AccessRules.GEO,
    title: 'Вызов геодезиста',
    flowType: FlowTypeEnum.GEO,
  },
];
