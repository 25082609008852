import { Injectable } from '@angular/core';
import { Rest } from './rest.service';
import { MeetingsListParams, MeetingStatus } from '@models';

@Injectable({ providedIn: 'root' })
export class MeetingStatusesService {
  constructor(private rest: Rest) {}

  fetchAll(params: MeetingsListParams) {
    return this.rest.get<MeetingStatus[]>('MeetingStatuses', params);
  }
}
