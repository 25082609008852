import { Injectable } from '@angular/core';
import { Rest } from './rest.service';
import {
  WorkControlData,
  Meeting,
  MeetingInfo,
  MeetingsListParams,
  MeetingsListMeta,
  ILink,
} from '@models';
import { CalendarService } from './calendar.service';
import { mapTo } from 'rxjs/operators';
import {
  FreeBusyTime,
  WorkCategoryDocument,
  WorkChecListAttachments,
} from '@app/models/work-control';
import { FlowTypeRequestParams, FlowTypeTab, UpdateMeetingUsersParams } from '@app/models/meeting';
import { DateTime } from 'luxon';

interface RejectInput {
  meetingId: number;
  comment: string;
  declineReasonId: number;
  files: File[];
  link: string;
}

interface FixResultInput {
  meetingId: number;
  comment: string;
  files: File[];
  link: string;
}

@Injectable({ providedIn: 'root' })
export class MeetingService {
  private attachments: WorkChecListAttachments = {};

  constructor(private rest: Rest, private calendarService: CalendarService) {}

  fetchAll() {
    return this.rest.get<Meeting[]>('Meeting');
  }

  fetchList(params: MeetingsListParams & MeetingsListMeta) {
    const options: MeetingsListParams = {};
    Object.keys(params).forEach((key) => {
      if (params[key] != null && key !== 'guestsNames' && key !== 'date') {
        if (Array.isArray(params[key])) {
          options[key] = params[key].join(',');
        } else {
          options[key] = params[key];
        }
      }
    });

    if (params.date !== undefined && params.date !== null) {
      options.date = DateTime.fromISO(params.date).toISO();
    }

    return this.rest.getOrigin<{ data: MeetingInfo[]; meta: MeetingsListMeta }>(
      'Meeting/list',
      options
    );
  }

  fetchById(id: number) {
    return this.rest.get<Meeting>(`Meeting/${id}`);
  }

  removeById(id: number) {
    return this.rest.delete<Meeting>(`Meeting/${id}`);
  }

  updateMeeting(id: number, data: WorkControlData) {
    return this.rest.patch<Meeting>(`Meeting/${id}`, this.getMeetingFormData(data, true));
  }

  rescheduleMeeting(id: number, data: FreeBusyTime | WorkControlData) {
    return this.rest.patch<Meeting>(`Meeting/${id}`, data);
  }

  confirmKMMeeting(id: number, data: WorkControlData) {
    const res = {
      start: this.calendarService.parseDateTime(data.date, data.time.start),
      end: this.calendarService.parseDateTime(data.date, data.time.end),
      guestGuid: data.guestGuid,
    };
    return this.rest.patch<Meeting>(`Meeting/progress/${id}`, res);
  }

  confirmSGMeeting(id: number) {
    return this.rest.patch<Meeting>(`Meeting/accept/${id}`, null);
  }

  createMeeting(data: WorkControlData) {
    return this.rest.post<{}>('Meeting', this.getMeetingFormData(data));
  }

  confirmMeeting(id: number) {
    return this.rest.get<boolean>(`Meeting/confirm/${id}`).pipe(mapTo(true));
  }

  rejectMeeting(data: RejectInput) {
    return this.rest.post<boolean>('Meeting/reject', this.mapToFormData(data)).pipe(mapTo(true));
  }

  acceptMeeting(id: number) {
    return this.rest.patch<Meeting>(`Meeting/accept-call/${id}`, null);
  }

  declineMeeting(id: number) {
    return this.rest.patch<Meeting>(`Meeting/decline-call/${id}`, null);
  }

  fixResult(data: FixResultInput) {
    return this.rest
      .post<boolean>('Meeting/fix-result', this.mapToFormData(data))
      .pipe(mapTo(true));
  }

  // fetchDocumentsForWork() {
  //   return this.rest.get<[]>('Meeting/documentsForWork');
  // }

  uploadDocument(file: File) {
    const formData = new FormData();
    formData.append('formFile', file, file.name);
    return this.rest.post<WorkCategoryDocument>('Document/file', formData);
  }

  uploadDocumentInMeeting(documentsIds, idMeeting) {
    return this.rest.post<WorkCategoryDocument>(`Meeting/add_documents/${idMeeting}`, {
      documents: [documentsIds],
    });
  }

  resendMeeting(idMeeting) {
    return this.rest.patch<Meeting>(`Meeting/wait_distribution/${idMeeting}`, null);
  }

  uploadLink(link: ILink) {
    return this.rest.post<WorkCategoryDocument>('Document/link', link);
  }

  fetchMeetingFiles(): WorkCategoryDocument[] {
    const files: WorkCategoryDocument[] = [];
    for (let checkListId in this.attachments) {
      for (let categoryName in this.attachments[checkListId]) {
        this.attachments[checkListId][categoryName].files.forEach((file) => files.push(file));
      }
    }
    return files;
  }

  fetchAttachments(): WorkChecListAttachments {
    return this.attachments;
  }

  storeAttachments(data: WorkChecListAttachments): void {
    this.attachments = data;
  }

  clearAttachments(): void {
    this.attachments = {};
  }

  fetchMeetingLinks(): WorkCategoryDocument[] {
    const links: WorkCategoryDocument[] = [];
    for (let checkListId in this.attachments) {
      for (let categoryName in this.attachments[checkListId]) {
        this.attachments[checkListId][categoryName].links.forEach((link) => links.push(link));
      }
    }
    return links;
  }

  updateMeetingUsers(body: UpdateMeetingUsersParams) {
    const { meetingId, objectId, users } = body;
    return this.rest.put<Meeting>(`Meeting/${body.meetingId}/update-users`, {
      meetingId,
      objectId,
      users,
    });
  }

  // private getMeetingFormData(data: WorkControlData, isUpdate?: boolean): FormData {
  //   const { objectId, metaInfo, checkLists, livingComplexId, date, time, description } = data;
  //   const formData = new FormData();
  //   const meetingRequest: any = {
  //     objectId,
  //     metaInfo: {
  //       description,
  //       sections: metaInfo.sections.map((section) => {
  //         const { floorNames, realEstateNames, sectionName, ...rest } = section;
  //         return { ...rest };
  //       })
  //     },
  //     checkLists: checkLists.map(({ checklistId, axis, highMark, scope = null }) =>
  //       ({ checklistId, axis, highMark, scope }))
  //   };
  //   if (!isUpdate || data.globalId) {
  //     meetingRequest.livingComplexId = livingComplexId;
  //     meetingRequest.start = this.calendarService.parseDateTime(date, time.start);
  //     meetingRequest.end = this.calendarService.parseDateTime(date, time.end);
  //   } else {
  //     return meetingRequest;
  //   }
  //   if (data.globalId) {
  //     if (Array.isArray(data.globalId)) {
  //       meetingRequest.users = data.globalId.map(hash => ({ globalId: hash}));
  //       return meetingRequest;
  //     }
  //     meetingRequest.user = { globalId: data.globalId };
  //     return meetingRequest;
  //   }
  //   if (this.fetchMeetingLinks().length) {
  //     meetingRequest.directDocsLink = this.fetchMeetingLinks();
  //   }

  //   formData.append('meetingRequest', JSON.stringify(meetingRequest));
  //   if (this.fetchMeetingFiles().length) {
  //     this.fetchMeetingFiles().forEach(f => formData.append('files', f, f.name));
  //   }
  //   return formData;
  // }

  private getMeetingFormData(data: WorkControlData, isUpdate?: boolean) {
    const {
      objectId,
      checkLists,
      companyId,
      date,
      time,
      description,
      team,
      start,
      end,
      contractorContact,
      idpTeamRoleId,
      flowTypeId,
    } = data;
    const meetingRequest: any = {
      objectId,
      checkLists,
      flowTypeId,
    };

    if (companyId) {
      meetingRequest.companyId = companyId;
    }

    if (start) {
      meetingRequest.start = start;
    }
    if (end) {
      meetingRequest.end = end;
    }

    meetingRequest.description = description || '';

    if (idpTeamRoleId) {
      meetingRequest.idpTeamRoleId = idpTeamRoleId;
    }
    if (contractorContact?.name || contractorContact?.phone) {
      meetingRequest.contractorContact = contractorContact;
    }
    if (data.fio && data.phone) {
      meetingRequest.contactInfo = {
        fio: data.fio,
        phone: data.phone,
      };
    }
    if (isUpdate && team) {
      meetingRequest.team = team;
    }
    if (!isUpdate || data.globalId) {
      meetingRequest.start = this.calendarService.parseDateTime(date, time.start);
      meetingRequest.end = this.calendarService.parseDateTime(date, time.end);
    } else {
      return meetingRequest;
    }
    if (data.globalId) {
      if (Array.isArray(data.globalId)) {
        meetingRequest.users = data.globalId.map((hash) => ({ globalId: hash }));
        return meetingRequest;
      }
      meetingRequest.user = { globalId: data.globalId };
      return meetingRequest;
    }
    if (this.fetchMeetingLinks().length) {
      const links = this.fetchMeetingLinks();
      meetingRequest.documents = links.map((l) => l.id);
    }
    if (this.fetchMeetingFiles().length) {
      const files = this.fetchMeetingFiles();
      meetingRequest.documents = meetingRequest.documents
        ? [...meetingRequest.documents, ...files.map((f) => f.id)]
        : [...files.map((f) => f.id)];
    }
    return meetingRequest;
  }

  private mapToFormData = (input: RejectInput | FixResultInput) => {
    const { files, ...rest } = input;
    const formData = new FormData();
    formData.append('request', JSON.stringify({ ...rest }));
    if (files) {
      files.forEach((f) => formData.append('files', f, f.name));
    }
    return formData;
  };

  getFlowType(params: FlowTypeRequestParams) {
    return this.rest.get<FlowTypeTab[]>(`FlowType`, params);
  }
}
