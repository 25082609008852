import { Team, User } from './user';
import { Company } from '@app/models/companies';

export enum CheckListControlTypes {
  DECORATION = 'Decoration',
}

export enum CheckListControlValidators {
  REQUIRED = 'necessary',
  OPTIONAL = 'optional',
}

export interface CheckList {
  checklistId: number;
  controlTypes: string[];
  checklistTitle: string;
  axis?: string;
  highMark?: string;
  scope?: string | null;
  checklistPathTitle?: string;
  documents: WorkDocumentCategory[] | null;
  sections?: WorkControlSection[];
}

export interface WorkControlCalendarData {
  date?: string;
  time?: {
    start: string;
    end: string;
  };
}

export interface WorkControlAxis {
  pos1?: string;
  pos2?: string;
  pos3?: string;
  pos4?: string;
}

export interface WorkControlSection {
  sectionId: number;
  sectionName?: string;
  floors: number[];
  floorNames?: string[];
  realEstates: number[];
  realEstateNames?: string[];
  axis?: WorkControlAxis;
  scope?: string | null;
}

export interface WorkControlMetaInfo {
  sections: WorkControlSection[];
}

export interface WorkControlData extends WorkControlCalendarData {
  livingComplexId?: number;
  livingComplexName?: string;
  objectId?: number;
  team?: Team[];
  objectName?: string;
  controlType?: string;
  controlTypeName?: string;
  start?: string;
  end?: string;
  checkLists?: CheckList[];
  guests?: User[];
  globalId?: string;
  metaInfo?: WorkControlMetaInfo;
  description?: string;
  fio?: string;
  phone?: string;
  objectTypeIds?: number[];
  guestGuid?: string;
  idpTeamRoleId?: number;
  flowTypeId?: number;
  minimum_metting_duration_min?: number;
  companyId?: number;
  company?: Company;
  contractorContact?: ContractorContact;
}

export interface ContractorContact {
  name?: string;
  phone?: string;
}

export interface LivingComplex {
  id: number;
  name: string;
  guid1c: string;
}

export interface WorkObject {
  id: number;
  name: string;
  code1C: string;
  commercialName: string;
}

export interface ObjectTypeRequiredFields {
  section: string;
  floor: string;
  axis: string;
  flats?: string;
}

export interface WorkObjectType {
  id: number;
  ext_id?: number;
  name?: string;
  type?: string;
  code?: string;
  mandatoryFields?: ObjectTypeRequiredFields;
}

export interface WorkType {
  id: number;
  name: string;
}

export interface WorkProperties {
  checkInitiatorTime: boolean;
  isEditDocuments: boolean;
}

export interface WorkChecklistItem {
  id: number;
  title: string;
  groupTitle?: string;
  isFolder: boolean;
  childs: WorkChecklistItem[] | null;
  controlTypes?: string[];
  pathTitle?: string;
  properties?: WorkProperties | null;
  documents: WorkDocumentCategory[] | null;
}

export interface WorkDocumentCategory {
  name: string;
  isRequired: boolean;
}

export interface WorkCategoryAttachments {
  [name: string]: {
    files: WorkCategoryDocument[];
    links: WorkCategoryDocument[];
  };
}

export interface WorkCategoryDocument {
  id: number;
  documentName: string;
}

export interface WorkChecListAttachments {
  [checkListId: number]: WorkCategoryAttachments;
}

export interface FreeBusyTime {
  start: string;
  end: string;
}

export interface FreeBusyData {
  [key: string]: FreeBusyTime[];
}
