export enum AccessRules {
  IC = 'AccessUnitIC',
  PIK_COMFORT = 'AccessUnitPIKComfort',
  KM = 'AccessUnitCR',
  SG = 'AccessUnitSpC',
  PP = 'AccessUnitPredpriem',
  GEO = 'AccessGeodezist',
  REPORTS = 'AccessUnitReports',
  ADMIN = 'AccessUnitAdministration',
  CALL_WAIT_DISTRIBUTION = 'CallWaitDistribution',
  EDIT = 'CallEdit',
  ADD = 'CallInitiate',
  DELETE = 'CallDelete',
  RESCHEDULE = 'CallReschedule',
  REJECT = 'CallReject',
  MAKE = 'CallMake',
  CONFIRM = 'CallConfirm',
  PROGRESS = 'CallProgress',
  CONFIRM_WORK = 'ConfirmWork',
  CONFIRM_DECISION = 'ConfirmDecision',
  CONFIRM_DECISION_GEO = 'ConfirmDecisionGeo',
  ACCEPT = 'CallAccept',
  DECLINE = 'CallDecline',
}

export const MAIN_RULES = [
  AccessRules.IC,
  AccessRules.KM,
  AccessRules.PIK_COMFORT,
  AccessRules.SG,
  AccessRules.PP,
  AccessRules.GEO,
  AccessRules.ADMIN,
  AccessRules.REPORTS,
  AccessRules.CALL_WAIT_DISTRIBUTION,
];

export interface AccessData {
  accessRule: string;
  status: number;
  flowTypeId: number;
}
