import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@services';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { AccessRules, CONTROL_TYPE_OPTIONS, MAIN_RULES, FlowTypeEnum } from '@models';
import { Store, Select } from '@ngxs/store';
import { RootStore } from './state/root.state';
import { SetFlowType } from './state/root.actions';

const ROUTES = {
  [AccessRules.IC]: '/registry',
  [AccessRules.KM]: '/registry',
  [AccessRules.PIK_COMFORT]: '/registry',
  [AccessRules.SG]: '/registry',
  [AccessRules.PP]: '/registry',
  [AccessRules.GEO]: '/registry',
  [AccessRules.ADMIN]: '/admin',
  [AccessRules.REPORTS]: '/operation-report',
};

@Injectable()
export class AccessRulesGuard implements CanActivate {
  @Select(RootStore.flowType) flowType$: Observable<FlowTypeEnum>;

  constructor(private service: AuthService, private store: Store, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.service.listCurrent().pipe(
      withLatestFrom(this.flowType$),
      map(([{ accessRules }, flowType]) => {
        if (
          route.url.length > 0 &&
          (accessRules.length === 0 ||
            (route.url[0].path === 'form' && !accessRules.includes(AccessRules.ADD)))
        ) {
          this.router.navigateByUrl('/');
          return false;
        }
        const filteredRules = accessRules.filter((r) => MAIN_RULES.includes(r));
        if (
          route.url.some((u) => u.path === 'admin') &&
          !filteredRules.includes(AccessRules.ADMIN)
        ) {
          this.router.navigateByUrl('/');
          return false;
        }
        if (filteredRules.length > 0) {
          const rule = filteredRules[0];
          const routePath = ROUTES[rule];
          if (routePath === '/registry') {
            const option = CONTROL_TYPE_OPTIONS.find((o) => o.rule === rule);
            if (
              option &&
              option.flowType !== flowType &&
              route.url.some((url) => url.path.includes('registry'))
            ) {
              this.store.dispatch(new SetFlowType(option.flowType));
            }
          }
          if (route.url.length === 0) {
            this.router.navigateByUrl(routePath);
            return false;
          }
        }
        return true;
      })
    );
  }
}
