import { Injectable } from '@angular/core';
import { Rest } from './rest.service';
import { User } from '@models';
import { IdpUser, Team } from '@app/models/user';

interface UserParams {
  dynamicFilter: string;
}
interface UserComfortParams {
  objectId: number;
  livingComplexId: number;
  meetingId?: number;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private rest: Rest) {}

  fetchAll(params?: UserParams) {
    return this.rest.get<User[]>('User', params);
  }

  fetchComfort(params?: UserComfortParams) {
    return this.rest.get<User[]>('User/list-comfort', params);
  }

  fetchBrigades() {
    return this.rest.get<Team[]>('team');
  }

  fetchIdpUsers(livingComplexId: number, objectId: number) {
    return this.rest.get<IdpUser[]>(`User/idp-team/${livingComplexId}/${objectId}`);
  }
}
