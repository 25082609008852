import { Role } from './role';

export interface User {
  globalId: string;
  login: string;
  email: string;
  fullName: string;
  type: number;
  status?: string;
  isAdmin: boolean;
  userRoleId: number;
  userRoleName: string;
  roles: Role[];
  team: Team[];
}

export interface Team {
  teamName: string;
  teamId: number;
}

export interface UsersListMeta {
  offset: number;
  limit: number;
  totalCount?: number;
}

// 39 Специалист строительного контроля (основной)

// 41 Специалист контроля качества отделки

// 46 Специалист авторского надзора

// 53 Специалист строительного контроля (замещающий)

// 58 Инженер по строительному контролю за СС

// 84 Инженер по строительному контролю за СС (замещающий)

// 94 Геодезист

// 122 Руководитель группы СС

// 125 Специалист по контролю за соблюдением санитарно-эпидемиологических требований

// 126 Инженер по строительному контролю за системами вентиляции

// 127 Заместитель руководителя группы по строительному контролю

// 128 Заместитель руководителя группы по контролю качества отделочных работ

// 133 Инженер лазерного сканирования

export const USER_ROLE_IDS = {
  AUTH: [46, 7, 9],
  BUILDING: [39, 53, 58, 84],
  CONTROL_VENTILATION: [125],
  SANITATION_SPECIALIST: [126],
  PIK_COMFORT: [1002, 1003, 1004],
  CADASTRAL: [1005, 1006],
  SPEC_GRAD: [1009],
  SURVEYORS: [94],
};

export enum UserRoleIdEnum {
  GUEST = 1001,
  INITIATOR = 1000,
  INITIATOR_SPEC_GRAD = 1008,
  CONTRACTOR = 7,
  FINISHING = 41,
  LASER_SCANNING = 133,
  AREA_MANAGEMENT = 10,
}

export type IdpUser = Pick<User, 'globalId' | 'fullName'> & {
  roleId: number;
};
