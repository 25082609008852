import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { environment } from '@env/environment';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AppRoutingModule } from './app-routing.module';
import { RootStore } from './state/root.state';
import { AppComponent } from './app.component';
import { BaseComponent } from './base.component';
import { AuthCallbackComponent } from './auth-callback.component';
import { AccessRulesGuard } from './access-rules.guard';
import { AuthModule, OpenIdConfiguration, AuthInterceptor } from 'angular-auth-oidc-client';
import { CustomStorage } from './services/custom-storage.service';
import { VersionInterceptor } from './services/version.interceptor';
import { NotificationsModule } from './shared/notifications/notifications.module';
import { HeaderModule } from './shared/header/header.module';
import { WINDOW_PROVIDERS } from '@app/services/window.service';

registerLocaleData(ru);

const CONFIG_OIDC: OpenIdConfiguration = {
  authority: environment.auth.stsUrl,
  redirectUrl: `${environment.frontendUrl}/auth-callback`,
  clientId: environment.auth.client_id,
  responseType: 'id_token token',
  scope: environment.auth.scope,
  postLogoutRedirectUri: `${environment.frontendUrl}`,
  startCheckSession: true,
  silentRenew: true,
  postLoginRoute: '/',
  forbiddenRoute: '/',
  unauthorizedRoute: `/`,
  // maxIdTokenIatOffsetAllowedInSeconds: 1200,
  // renewTimeBeforeTokenExpiresInSeconds: 10,
  silentRenewUrl: `${environment.frontendUrl}/silent-renew.html`,
  storage: new CustomStorage(),
  secureRoutes: [environment.api],
  // logLevel: LogLevel.Debug,
};

@NgModule({
  declarations: [AppComponent, BaseComponent, AuthCallbackComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxsModule.forRoot([RootStore], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({ key: ['workControl', 'root'] }),
    NotificationsModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AuthModule.forRoot({
      config: CONFIG_OIDC,
    }),
    HeaderModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    AccessRulesGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
