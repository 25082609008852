import { User } from './index';
import { ILink } from './link';
import { Team } from '@app/models/user';

export interface MeetingObject {
  name: string;
  commercialName: string;
  shortName: string;
  code1C: string;
  directionId: number;
  cityId: number;
  projectId: number;
  livingComplexId: number;
  livingComplexName: string;
  objectCategoryId: number;
  objectKindId: number;
  objectSeriesId: number;
  objectTypeId: number;
  objectProjectTypeId: number;
  guid1c: string;
  isRenovation: true;
  id: number;
  created: string;
  modified: string;
}

export interface MeetingCheckList {
  checklistTitle: string;
  checklistId: number;
  scope?: string;
  axis?: string;
  highMark?: string;
  id?: number;
  created?: string;
  modified?: string;
  hint?: {};
  checklistPathTitle?: string;
  sections?: MeetingSection[];
  meetingSectionResponses?: MeetingSection[];
  objectTypeId: number;
  controlTypes?: [];
}

// export interface ChecklistWorkResponse {
//   fullTitle: string;
//   checklistId: number;
//   meetingSectionResponses: MeetingSection[];
//   sections?: MeetingSection[];
// }

export interface MeetingType {
  meetingId: number;
  typeId: number;
  typeName: string;
  id: number;
  created: string;
  modified: string;
}

export interface MeetingDocument {
  meetingId?: number;
  documentName: string;
  documentBucket?: string;
  documentPath?: string;
  docId?: string;
  id: number;
  created?: string;
  modified?: string;
  directDocLinks?: ILink[];
  type: number;
}

export interface MeetingStatus {
  id: number;
  name: string;
}

export interface MeetingFloor {
  id: number;
  name: string;
}

export interface MeetingRealEstate {
  id: number;
  name: string;
}

export interface MeetingAxis {
  pos1?: string;
  pos2?: string;
  pos3?: string;
  pos4?: string;
}

export interface MeetingSection {
  sectionId: number;
  sectionName: string;
  //todo привести в порядок тип для floors (MeetingFloor[]/number[])
  floors: any;
  realEstates: MeetingRealEstate[];
  axis?: MeetingAxis;
  scope?: string;
  elementConstruction?: ElementConstruction[];
  highMark: string;
}

export interface ElementConstruction {
  id: number;
  displayName: string;
  name: string;
  parentId: number;
  parentType: string;
  axis: MeetingAxis;
  scope?: string;
}

export interface MeetingMetaInfo {
  sections: MeetingSection[];
  description?: string;
}

export interface MeetingResolution {
  comment: string;
  link?: string;
  description: string;
  resolutionDate: string;
  resolutionUser: string;
}

export type Comments = {
  comment: string;
  date: string;
  reason: string;
  user: string;
  userRole: string;
  link: string;
  documents: MeetingDocument[];
};

export interface Meeting {
  // TODO: Добавить тип/enum
  name: string;
  objectId: number;
  meetingStartDate: string;
  meetingEndDate: string;
  status: MeetingStatus;
  object: MeetingObject;
  comments: Comments[];
  checklists: MeetingCheckList[];
  meetingTypes: MeetingType[];
  documents: MeetingDocument[];
  company: { id: number; name: string; inn: string };
  id: number;
  created: string;
  description: string;
  modified: string;
  users: User[];
  team: Team[];
  metaInfo: MeetingMetaInfo;
  isReadOnly: boolean;
  resolution: MeetingResolution;
  checklistWorkResponse?: MeetingCheckList[];
  declineReason: any;
  contactInfo?: {
    fio?: string;
    phone?: string;
  };
  contractorContact?: {
    name?: string;
    phone?: string;
  };
  objectTypes?: {
    id: number;
    name: string;
  }[];
}

export interface MeetingInfo {
  meetingEndDate: string;
  objectName: string;
  objectId: number;
  status: MeetingStatus;
  id: number;
  created: string;
  modified: string;
}

export interface MeetingsListMeta {
  offset: number;
  limit: number;
  totalCount?: number;
}

export interface MeetingsListParams {
  flowTypeId?: string | number;
  statusId?: number | number[];
  flowType?: number;
  date?: string;
  livingComplexId?: number[];
  guestGlobalId?: string[];
  guestsNames?: { fullName: string; globalId: string }[];
  meetingId?: string;
  SortType?: boolean;
}

export interface FlowTypeRequestParams {
  sortBy: string;
}

export interface FlowTypeTab {
  access_rule_id: number;
  access_rule_name: string;
  id: number;
  name: string;
}

export enum MeetingStatusIdEnum {
  IN_PROGRESS = 10,
  CANCELLED = 20,
  REJECTED = 30, // IsPlucked
  CONTROL_CONFIRMATION = 40, // IsAwaitingControlConfirmation
  NEED_DOCUMENTS = 75, // IsAwaitingControlConfirmation
  ADMIN_CONFIRMATION = 50, // IsAwaitingAdminConfirmation
  COMPLETED = 60,
  DISTRIBUTION = 70, // IsAwaitingDistribution
  UNKNOWN = 100,
}

export const MEETING_COLORS: { id: number; color: string; background: string }[] = [
  { id: MeetingStatusIdEnum.IN_PROGRESS, color: '#1EAE45', background: '#E5F8EA' },
  { id: MeetingStatusIdEnum.CANCELLED, color: '#777777', background: '#f5f5f5' },
  { id: MeetingStatusIdEnum.REJECTED, color: '#FA2110', background: '#FFF1EE' },
  { id: MeetingStatusIdEnum.CONTROL_CONFIRMATION, color: '#4688F1', background: '#dfecff' },
  { id: MeetingStatusIdEnum.NEED_DOCUMENTS, color: '#4688F1', background: '#dfecff' },
  { id: MeetingStatusIdEnum.ADMIN_CONFIRMATION, color: '#AE1E99', background: '#fff0fd' },
  { id: MeetingStatusIdEnum.COMPLETED, color: '#FF9C28', background: '#FFF4EA' },
];

export type UpdateMeetingUsersParams = {
  meetingId: number;
  objectId: number;
  users: { roleId: number; globalId: string }[];
};
