import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Notification } from '@models';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Input() item: Notification;
  @Output() closeEvent = new EventEmitter<Notification>();

  close() {
    this.closeEvent.emit(this.item);
  }
}
