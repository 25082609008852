import { Injectable } from '@angular/core';
import { Rest } from './rest.service';
import { WorkObjectType } from '@models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ObjectTypesService {
  constructor(private rest: Rest) {}

  fetchAll(): Observable<WorkObjectType[]> {
    return this.rest.get<WorkObjectType[]>('Object/type', {
      ObjectCodes: 'element,flat,mop',
      limit: '200',
    });
  }
}
