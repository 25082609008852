import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback',
  template: '<h3>Вход выполнен. Завершение авторизации...</h3>',
})
export class AuthCallbackComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {
    this.router.navigateByUrl('/');
  }
}
