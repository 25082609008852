<header class="header">
  <div class="header__logo">
    <svg
      _ngcontent-kwf-c58=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      fill="none"
      class="header__logo-image"
    >
      <path
        _ngcontent-kwf-c58=""
        d="M0.962891 32.7404V17.333H15.9209V32.7404H11.6041V21.154H5.27962V32.7404H0.962891ZM28.6703 23.8247L21.7635 32.7404H17.9286V17.333H22.2454V26.2488L29.1521 17.333H32.987V32.7404H28.6703V23.8247ZM39.3115 26.5364V32.7404H34.9948V17.333H39.3115V23.7219L44.4715 17.333H49.5111L43.3672 24.8107L51.037 32.7404H45.2345L39.3115 26.5364Z"
        fill="#FC4C02"
      ></path>
    </svg>
  </div>
  <div class="header__logout">
    <button class="btn btn-default" (click)="logout()">Выйти</button>
  </div>
</header>
