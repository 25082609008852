import { State, Action, StateContext, Selector } from '@ngxs/store';
import { CloseNotification, SetControlType, SetFlowType, ShowNotification } from './root.actions';
import { ControlTypeEnum, CONTROL_TYPE_IDS, FlowTypeEnum, Notification } from '@models';
import { Injectable } from '@angular/core';

interface RootState {
  controlType: ControlTypeEnum;
  flowType: number;
  notifications: Notification[];
}

@State<RootState>({
  name: 'root',
  defaults: { controlType: null, flowType: null, notifications: [] },
})
@Injectable()
export class RootStore {
  @Selector()
  static controlType(state: RootState): string {
    return state.controlType;
  }
  @Selector()
  static flowType(state: RootState): number {
    return state.flowType;
  }
  @Selector()
  static controlTypeIds(state: RootState): number[] {
    return CONTROL_TYPE_IDS[state.controlType] || [];
  }
  @Selector()
  static isPikComfort(state: RootState): boolean {
    return state.flowType === FlowTypeEnum.PIK_COMFORT;
  }
  @Selector()
  static isIC(state: RootState): boolean {
    return state.flowType === FlowTypeEnum.IC;
  }
  @Selector()
  static isKM(state: RootState): boolean {
    return state.flowType === FlowTypeEnum.KM;
  }
  @Selector()
  static isSG(state: RootState): boolean {
    return state.flowType === FlowTypeEnum.SG;
  }

  @Selector()
  static isPP(state: RootState): boolean {
    return state.flowType === FlowTypeEnum.PP;
  }

  @Selector()
  static isGeo(state: RootState): boolean {
    return state.flowType === FlowTypeEnum.GEO;
  }

  @Selector()
  static notifications(state: RootState): Notification[] {
    return state.notifications;
  }

  @Action(SetControlType)
  setControlType({ patchState }: StateContext<RootState>, { type }: SetControlType) {
    return patchState({ controlType: type || null });
  }

  @Action(SetFlowType)
  setFlowType({ patchState }: StateContext<RootState>, { type }: SetFlowType) {
    return patchState({ flowType: type });
  }

  @Action(ShowNotification)
  showNotification({ patchState, getState }: StateContext<RootState>, { data }: ShowNotification) {
    const { notifications } = getState();

    return patchState({ notifications: notifications.concat(data) });
  }

  @Action(CloseNotification)
  closeNotification({ patchState }: StateContext<RootState>) {
    return patchState({ notifications: [] });
  }
}
