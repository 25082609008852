import { Injectable } from '@angular/core';
import { DateTime, Settings } from 'luxon';
Settings.defaultLocale = 'ru';

export interface CalendarMonth {
  id: number;
  title: string;
  active: boolean;
}
export interface CalendarDay {
  id: number;
  weekDay: number;
  weekDayShort: string;
  active: boolean;
  disabled: boolean;
}
export interface CalendarTimeSlot {
  time: string;
  index: number;
}
export interface CalendarInterval {
  start: string;
  end: string;
}

const CURRENT_YEAR = DateTime.local().year;

//горящая правка для года freebusy
//todo переписать логику работы методов с учетом года из даты, а не CURRENT_YEAR
const getYear = (month: number) => {
  if (DateTime.local().month === 12 && month === 1) {
    return DateTime.local().year + 1;
  } else {
    return DateTime.local().year;
  }
};

@Injectable({ providedIn: 'root' })
export class CalendarService {
  fetchMonths(date?: string): CalendarMonth[] {
    const start = this.getFirstActiveDay(DateTime.local());
    const selectedDate = date ? DateTime.fromISO(date) : null;

    return [start, start.plus({ months: 1 })].map((month, index) => ({
      id: month.month,
      title: month.monthLong,
      active: selectedDate ? selectedDate.hasSame(month, 'month') : index === 0,
    }));
  }

  fetchDaysByMonth(monthNum: number, date?: string): CalendarDay[] {
    const days = [];
    const selectedDate = date ? DateTime.fromISO(date) : null;
    let current = DateTime.local(getYear(monthNum), monthNum);
    while (current.month === monthNum) {
      days.push({
        id: current.day,
        weekDay: current.weekday,
        weekDayShort: current.weekdayShort,
        active: (selectedDate && selectedDate.hasSame(current, 'day')) || false,
        date: current.toFormat('YYYY-MM-DD'),
      });
      current = current.plus({ days: 1 });
    }
    return days;
  }

  getFirstActiveDay(date: DateTime): DateTime {
    const nextDate = date.plus({ days: 1 });
    if (this.isBusyDay(nextDate.day, nextDate.month, [], true)) {
      return this.getFirstActiveDay(nextDate);
    }
    return nextDate;
  }

  getDateByMonthDay(month: number, day: number): string {
    return DateTime.local(getYear(month), month, day).toISO();
  }

  getTimeFromIsoDate(isoDate: string): string {
    return DateTime.fromISO(isoDate, { zone: 'utc' }).toLocal().toFormat('HH:mm');
  }

  getTimeSlotsByBusyTime(
    startDate: string,
    endDate: string,
    index: number,
    plusTime?: number,
    time?: string,
    interval: number = 30
  ): CalendarTimeSlot[] {
    let date = DateTime.fromISO(startDate, { zone: 'utc' }).toLocal();
    if (time) {
      const currentHour = Number(time.slice(0, 2));
      const currentMinute = Number(time.slice(3, 5));
      date = date.set({ hour: currentHour, minute: currentMinute });
    }
    if (date.minute < interval) {
      date = date.set({ minute: 0 });
    }
    const dateTo = DateTime.fromISO(endDate, { zone: 'utc' }).toLocal();
    if (plusTime) {
      date = date.plus({ minutes: plusTime });
    }
    const slots = [{ index, time: date.toFormat('HH:mm') }];

    while (dateTo > date) {
      date = date.plus({ minutes: interval });
      if (date <= dateTo) {
        slots.push({ index, time: date.toFormat('HH:mm') });
      }
    }

    return slots;
  }

  getSlotsFromIntervals(
    dateTimes: CalendarInterval[],
    plusTime?: number,
    currentTime?: string,
    interval?: number
  ): CalendarTimeSlot[] {
    return dateTimes.reduce(
      (slots, nx, index) =>
        slots.concat(
          this.getTimeSlotsByBusyTime(nx.start, nx.end, index, plusTime, currentTime, interval)
        ),
      []
    );
  }

  getTimeSlots(busySlots: CalendarTimeSlot[], isIC?: boolean): CalendarTimeSlot[] {
    const slots = [];
    const end = DateTime.local()
      .startOf('day')
      .plus({ hours: isIC ? 23 : 18 });
    let date = DateTime.local()
      .startOf('day')
      .plus({ hours: isIC ? 8 : 9 });
    while (end >= date) {
      const time = date.toFormat('HH:mm');
      const busySlot = busySlots.find((s) => s.time === time);
      if (busySlot) {
        slots.push({ time, index: busySlot.index });
      }
      date = date.plus({ minutes: 30 });
    }
    return slots && slots.length > 1
      ? slots.filter((slot) => slots.filter((s) => s.index === slot.index).length > 1)
      : [];
  }

  getNoFreeTimeSlots(isIC?: boolean, isKM?: boolean): CalendarTimeSlot[] {
    const slots = [];
    const end = DateTime.local()
      .startOf('day')
      .plus({ hours: isIC ? 23 : isKM ? 20 : 18 });
    let date = DateTime.local()
      .startOf('day')
      .plus({ hours: isIC || isKM ? 8 : 9 });
    while (end >= date) {
      const time = date.toFormat('HH:mm');
      slots.push({ time, index: 0 });
      date = date.plus({ minutes: 30 });
    }
    return slots && slots.length > 1
      ? slots.filter((slot) => slots.filter((s) => s.index === slot.index).length > 1)
      : [];
  }

  isBusyDay(
    day: number,
    month: number,
    intervals?: CalendarInterval[],
    isCreate?: boolean,
    isPC?: boolean,
    busy?: boolean
  ): boolean {
    const today = DateTime.utc();
    const toCompare = DateTime.utc(getYear(month), month, day);
    if (intervals && today.startOf('day') < toCompare.startOf('day') && busy) {
      if (intervals.length === 0) {
        return true;
      }
    }
    if (today.startOf('day') >= toCompare.startOf('day')) {
      return true;
    }
    if (!isPC && isCreate) {
      const isNextDay = today.hour > 15 || (today.hour === 15 && today.minute >= 30);
      if (isNextDay && toCompare.toISODate() === today.plus({ days: 1 }).toISODate()) {
        return true;
      }
    }
    return false;
  }

  isFactDateDay(day: number, month: number, factDate: string) {
    const date = DateTime.fromISO(factDate, { zone: 'utc' });
    const toCompare = DateTime.utc(getYear(month), month, day);
    return toCompare >= date.minus({ days: 30 }) && toCompare <= date.minus({ days: 14 });
  }

  isSameDay(day: number, month: number, isoDate: string): boolean {
    const isoDay = DateTime.fromISO(isoDate, { zone: 'utc' }).startOf('day');
    const toCompare = DateTime.utc(getYear(month), month, day).startOf('day');
    return isoDay.equals(toCompare);
  }

  parseDateTime(date: string, time: string): string {
    const [hours, minutes] = time.split(':');
    return DateTime.fromISO(date)
      .set({ hour: Number(hours), minute: Number(minutes) })
      .toUTC()
      .toISO({ includeOffset: false })
      .split('.')[0];
  }

  sortIntervals(a: CalendarInterval, b: CalendarInterval): number {
    return DateTime.fromISO(a.start).toMillis() - DateTime.fromISO(b.start).toMillis();
  }
}
