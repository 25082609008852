import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '@models';
import { Select, Store } from '@ngxs/store';
import { RootStore } from '@app/state/root.state';
import { CloseNotification } from '@app/state/root.actions';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  @Select(RootStore.notifications) notifications$: Observable<Notification[]>;

  constructor(private store: Store) {}

  onClose(notification: Notification) {
    this.store.dispatch(new CloseNotification(notification));
  }
}
