import { Injectable } from '@angular/core';
import { Rest } from './rest.service';
import { FreeBusyData } from '@models';
import { ObjectTypesData } from '@app/models/object-types';

export interface FreeBusyParams {
  livingComplexId: number;
  objectId: number;
  checklistIds?: string;
  idpTeamRoleId?: number;
  MinDurationInMinutes?: number;
  ExceptionMeetingId?: number;
}

@Injectable({ providedIn: 'root' })
export class FreeBusyService {
  constructor(private rest: Rest) {}

  fetchFreeTime(params: FreeBusyParams) {
    return this.rest.get<{ disallowChooseTime: boolean; intervals: FreeBusyData }>(
      'FreeBusy/freeTime_v2',
      params
    );
  }

  fetchById(globalId: string) {
    return this.rest.get<FreeBusyData>(`FreeBusy/${globalId}`);
  }

  fetchDurationMin(objectTypes?: ObjectTypesData[]) {
    return this.rest.patch<number>(`FreeBusy/minimum_metting_duration_min`, { objectTypes });
  }

  constructionExist(params: FreeBusyParams) {
    return this.rest.get<FreeBusyData>('FreeBusy/construction-exist', params);
  }
}
