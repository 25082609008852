import { Component } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { environment } from '@env/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { addMetrikaScript } from './util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  isProd = environment.production;

  constructor(private config: NgSelectConfig, public oidcSecurityService: OidcSecurityService) {
    this.config.loadingText = 'Загрузка...';
    this.config.notFoundText = '';

    if (this.isProd) {
      addMetrikaScript();
      this.oidcSecurityService.userData$.subscribe((user) => {
        if ((window as any).ym && user.userData) {
          (<any>window).ym(96133870, 'setUserID', user.userData.email);
          (<any>window).ym(96133870, 'userParams', {
            name: user.userData.name,
            email: user.userData.email,
          });
        }
      });
    }
  }
}
