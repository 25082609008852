export const environment = {
  api: 'https://rc-api.test.doc.pik-digital.ru/api/',
  frontendUrl: document.location.origin,
  auth: {
    client_id: 'reception_calendar_spa',
    scope:
      'openid profile email offline_access reception_calendar_api home_api wiki_api navigation_api photo_api allow_external',
    stsUrl: 'https://test.auth.pik-digital.ru',
  },
  needAuth: true,
  production: false,
  photoApi: 'https://api.test.photo.pik-digital.ru/',
};
