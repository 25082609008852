import { Injectable } from '@angular/core';
import { Execution } from '@app/models/execution';
import { Observable } from 'rxjs';
import { Rest } from './rest.service';

interface ExecutionsParams {
  livingComplexId: number;
  objectId: number;
  milestoneId: number;
}

@Injectable({ providedIn: 'root' })
export class ExecutionsService {
  constructor(private rest: Rest) {}

  getExecutions(params: ExecutionsParams): Observable<Execution[]> {
    return this.rest.get<Execution[]>('execution/executions', params, false);
  }
}
